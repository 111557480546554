import { createContext, useContext } from 'react';
import { UserType } from '@les-sherpas/sherpas-toolbox';

type NavbarContextValue = {
  isConnected?: boolean;
  photoUrl?: string;
  firstName?: string;
  lastName?: string;
  userType?: UserType;
  isLandingPage: boolean;
  notificationsCount?: number;
  route?: string;
};

export const NavbarContext = createContext<NavbarContextValue>({
  isLandingPage: false,
} satisfies NavbarContextValue);

export const useNavbarContext = () => {
  const context = useContext(NavbarContext);
  if (!context) {
    throw new Error(
      "le context ne peut être utilisé si le provider NavbarContextProvider n'a pas été setté"
    );
  }
  return context;
};
