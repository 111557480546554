import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export type StyleProps = { isActive?: boolean };

export default makeStyles<StyleProps>()(({ palette }, { isActive }) => ({
  link: {
    padding: `${rem(4)} ${rem(12)}`,
    fontWeight: 500,
    borderRadius: rem(8),
    backgroundColor: isActive && palette.neutral[900],
    '&:hover': {
      backgroundColor: palette.neutral[900],
    },
  },
}));
