import { FC, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { UserType } from '@les-sherpas/sherpas-toolbox';
import { Button } from '@mui/material';

import NotificationChip from '@/components/NotificationChip/NotificationChip';
import SalesPhoneNumber from '@/components/SalesPhoneNumber/SalesPhoneNumber';
import useNavigation from '@/shared/Hooks/useNavigation';
import { useNavbarContext } from '@/shared/Navbar/context/NavbarContext';

import ItemNavigationLink from './ItemNavigationLink/ItemNavigationLink';
import messages from './messages';

import useStyles from './styles';

type Props = {
  onLinkClick?: () => void;
  activeLink?: string;
};

const ItemNavigation: FC<PropsWithChildren<Props>> = ({
  onLinkClick,
  activeLink = '',
}) => {
  const { notificationsCount, userType } = useNavbarContext();
  const { classes } = useStyles();
  const { pushTo, urls } = useNavigation();

  const handleLinkClick = (e: string) => {
    window.dataLayer.push({
      menu: e,
      event: 'header_menu',
    });
    onLinkClick?.();
  };

  /** On est déconnecté */
  if (userType !== UserType.Student && userType !== UserType.Teacher) {
    return (
      <div className={classes.container}>
        <ItemNavigationLink
          href="/donner-cours-particuliers"
          onClick={() => handleLinkClick(messages.giveCourses.defaultMessage)}
        >
          <FormattedMessage {...messages.giveCourses} />
        </ItemNavigationLink>
        <ItemNavigationLink
          href={urls.blog()}
          onClick={() => handleLinkClick(messages.media.defaultMessage)}
        >
          <FormattedMessage {...messages.media} />
        </ItemNavigationLink>
        <Button
          className={classes.link}
          onClick={async () => {
            handleLinkClick(messages.help.defaultMessage);
            await pushTo(urls.helpCenter());
          }}
          data-mozza="navigationLink"
          disableRipple
        >
          <FormattedMessage {...messages.help} />
        </Button>
        <SalesPhoneNumber
          eventName="salesNumber_navbar_unauthenticated"
          variant="smallBlue"
          label="callUs"
        />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <ItemNavigationLink
        href={urls.dashboardSummary()}
        isActive={activeLink === urls.dashboardSummary()}
        onClick={() => handleLinkClick(messages.dashboard.defaultMessage)}
      >
        <FormattedMessage {...messages.dashboard} />
      </ItemNavigationLink>
      <ItemNavigationLink
        href={urls.chatRooms()}
        isActive={activeLink === urls.chatRooms()}
        onClick={() => handleLinkClick(messages.messages.defaultMessage)}
      >
        <FormattedMessage {...messages.messages} />
        <NotificationChip isShown={notificationsCount > 0} />
      </ItemNavigationLink>
      {userType === UserType.Teacher && (
        <ItemNavigationLink
          href={urls.teacherAnnounces()}
          isActive={activeLink === urls.teacherAnnounces()}
          onClick={() => handleLinkClick(messages.announces.defaultMessage)}
        >
          <FormattedMessage {...messages.announces} />
        </ItemNavigationLink>
      )}
      {userType === UserType.Student ? (
        <ItemNavigationLink
          href={urls.resources()}
          onClick={() => handleLinkClick(messages.resources.defaultMessage)}
        >
          <FormattedMessage {...messages.resources} />
        </ItemNavigationLink>
      ) : (
        <ItemNavigationLink
          href={urls.blog()}
          onClick={() => handleLinkClick(messages.media.defaultMessage)}
        >
          <FormattedMessage {...messages.media} />
        </ItemNavigationLink>
      )}
      {userType !== UserType.Teacher && (
        <SalesPhoneNumber
          eventName="salesNumber_navbar_authenticated"
          variant="smallBlue"
          label="callUs"
        />
      )}
    </div>
  );
};

export default ItemNavigation;
