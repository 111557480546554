import {
  ElementType,
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Typography } from '@mui/material';

import { usePreFetchAuthentication } from '@/components/Authentication/useAuthentication';
import Avatar from '@/designSystem/Avatar/Avatar';
import Hidden from '@/generic/Hidden/Hidden';
import Dropdown from '@/Molecules/User/Dropdown/Dropdown';
import { UserVariant } from '@/Molecules/User/User.type';
import logout from '@/Molecules/User/User.utils';

import messages from './messages';

import useStyles from './styles';

type Props = {
  photoUrl?: string;
  firstName: string;
  lastName: string;
  isWithDropDown?: boolean;
  variant?: UserVariant;
  shouldDisplayFirstName?: boolean;
  showDropdownArrow?: boolean;
};

const User: FC<PropsWithChildren<Props>> = ({
  isWithDropDown = false,
  variant = 'bodyMd',
  photoUrl = null,
  firstName,
  lastName,
  shouldDisplayFirstName = false,
  showDropdownArrow = false,
}) => {
  const dropdown = useRef();
  const { prefetchAuthentication } = usePreFetchAuthentication();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { classes } = useStyles({
    variant,
    isDropdownOpen,
    isWithDropDown,
  });
  const HtmlTag = (isWithDropDown ? 'button' : 'div') as ElementType;

  const onLogOutClick = async () => {
    await logout();
    prefetchAuthentication();
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdown?.current &&
        // eslint-disable-next-line spaced-comment
        //@ts-ignore
        !dropdown?.current?.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdown]);

  return (
    <HtmlTag
      ref={dropdown}
      className={classes.avatarContainer}
      onClick={() => isWithDropDown && setIsDropdownOpen(!isDropdownOpen)}
      data-mozza="navigationAvatar"
    >
      <Avatar
        photoUrl={photoUrl}
        firstName={firstName}
        lastName={lastName}
        avatarSize="medium"
      />
      {shouldDisplayFirstName && (
        <Hidden variant="sm">
          <Typography className={classes.name} variant={`${variant}Bold`}>
            {firstName}
          </Typography>
        </Hidden>
      )}
      {isWithDropDown && (
        <>
          {showDropdownArrow && (
            <ExpandMoreRoundedIcon className={classes.iconOrientation} />
          )}
          {isDropdownOpen && (
            <Dropdown>
              <Dropdown.Link href="/parametre">
                <FormattedMessage {...messages.parameters} />
              </Dropdown.Link>
              <Dropdown.Link href="/centre-aide">
                <FormattedMessage {...messages.helpCenter} />
              </Dropdown.Link>
              <hr className={classes.separator} />
              <Dropdown.Link
                href="/"
                className={classes.logout}
                onClick={onLogOutClick}
              >
                <FormattedMessage {...messages.logout} />
              </Dropdown.Link>
            </Dropdown>
          )}
        </>
      )}
    </HtmlTag>
  );
};

export default User;
