import { defineMessages } from 'react-intl';

export default defineMessages({
  board: {
    id: 'navbar.board',
    defaultMessage: 'Tableau de bord',
  },
  messages: {
    id: 'navbar.messages',
    defaultMessage: 'Messages',
  },
  announces: {
    id: 'navbar.announces',
    defaultMessage: 'Annonces',
  },
  dashboard: {
    id: 'navbar.dashboard',
    defaultMessage: 'Tableau de bord',
  },
  lessons: {
    id: 'navbar.lessons',
    defaultMessage: 'Cours',
  },
  giveCourses: {
    id: 'navbar.give.courses',
    defaultMessage: 'Donner des cours',
  },
  media: {
    id: 'navbar.blog',
    defaultMessage: 'Blog',
  },
  help: {
    id: 'navbar.help',
    defaultMessage: 'Aide',
  },
  resources: {
    id: 'navbar.resources',
    defaultMessage: 'Méthodologie',
  },
});
