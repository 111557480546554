import {
  ChangeEvent,
  FC,
  HTMLAttributes,
  PropsWithChildren,
  ReactEventHandler,
} from 'react';

import { UseFormInputBlur, UseFormInputChange } from '@/hooks/useForm';
import Adornment, {
  AdornmentType,
} from '@/Molecules/Input/Adornment/Adornment';
import Tag, { TagProps } from '@/Molecules/Input/Tag/Tag';

import useStyles from './styles';

export type InputProps = {
  type: 'text' | 'password' | 'email' | 'tel' | 'number' | 'date';
  placeholder: string;
  value?: string | number;
  max?: number;
  min?: number;
  name?: string;
  required?: boolean;
  onChange?: ReactEventHandler;
  onBlur?: ReactEventHandler;
  onChangeForUseForm?: UseFormInputChange<string>;
  onBlurForUseForm?: UseFormInputBlur<string>;
  containerClassName?: string;
  disabled?: boolean;
  maxLength?: number;
  isReadOnly?: boolean;
  autofocus?: boolean;
  errors?: Record<string, string>;
};

type Compound = {
  Adornment: FC<PropsWithChildren<AdornmentType>>;
  Tag: FC<PropsWithChildren<TagProps>>;
};

const Input: FC<
  PropsWithChildren<InputProps & HTMLAttributes<HTMLInputElement>>
> &
  Compound = ({
  type,
  placeholder,
  onChange,
  onBlur,
  onChangeForUseForm,
  onBlurForUseForm,
  name = 'defaultInputName',
  value,
  required = false,
  className = '',
  containerClassName = '',
  isReadOnly = false,
  children,
  autofocus = false,
  ...rest
}) => {
  const { classes, cx } = useStyles();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChangeForUseForm) onChangeForUseForm({ name, value: e.target.value });
    if (onChange) onChange(e);
  };

  const handleBlur = (e) => {
    if (onBlurForUseForm) onBlurForUseForm({ name, value: e.target.value });
    if (onBlur) onBlur(e);
  };

  return (
    <div className={cx(classes.root, containerClassName)}>
      <input
        className={cx(classes.input, className)}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        required={required}
        onBlur={handleBlur}
        onChange={handleChange}
        readOnly={isReadOnly}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autofocus}
        {...rest}
      />
      {children}
    </div>
  );
};

Input.Adornment = Adornment;
Input.Tag = Tag;

export default Input;
